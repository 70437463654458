import { Flex, Skeleton, Stack, VStack } from "@chakra-ui/react";
import React from "react";
import { useDispatch, useSelector } from "react-redux";

function App() {
  const dispatch = useDispatch();
  const data = useSelector((state) => state.data);
  return (
    <>
      <Flex
        w={"full"}
        h={"100vh"}
        backgroundImage={"/config/images/bg5.webp"}
        backgroundSize={"cover"}
        backgroundPosition={"center center"}
      >
        <VStack
          w={"full"}
          justify={"center"}
          px={8}
          bgGradient={"linear(to-r, blackAlpha.600, transparent)"}
        >
          <Stack align={"center"} spacing={6}>
            <Skeleton
              color={"gray.900"}
              fontWeight={700}
              fontFamily={"heading"}
              lineHeight={1.2}
              fontSize={{ base: "30vw" }}
              startColor="black"
              endColor="red.900"
              speed={2.5}
              rounded={"2xl"}
              w={"85vw"}
              textAlign={"center"}
            >
              KaiTi
            </Skeleton>
            <Skeleton
              color={"gray.900"}
              fontWeight={400}
              fontFamily={"heading"}
              lineHeight={1.5}
              fontSize={{ base: "5vw" }}
              startColor="gray.900"
              endColor="black"
              speed={5}
              rounded={"2xl"}
              w={"85vw"}
              textAlign={"center"}
            >
              COMING SOON
            </Skeleton>
          </Stack>
        </VStack>
      </Flex>
    </>
  );
}

export default App;
